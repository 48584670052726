<template>
	<div>
		<van-nav-bar
			title="收据列表"
			left-arrow
			fixed
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		
		<div class="invoice">
			<van-list 
				v-if="hasData"
				@load="getList" 
				:offset="0" 
				class="list"
				v-model="loading"
				:finished="finished"
				:immediate-check="false"
			>
				<div 
					slot="default" 
					v-for="(item, index) in lists" 
					:key="item.id" 
					class="qjc-bg-fff"
					@click="toInvoice(item)"
				>
					<div class="title qjc-clearfix">
						<div class="qjc-fl">
							<van-image
								width="0.19rem"
								height="0.2rem"
								:src="require('@/assets/images/dingdanhao.png')"
							/>
							{{ item.order_no }}
						</div>
						<div class="qjc-fr qjc-relative">
							<span v-if="item.kp == 1" class="qjc-c-primary">
								<span>申請開票</span>
							</span>
							<span v-if="item.kp == 2" class="qjc-c-primary">
								<van-image
									width="1.12rem"
									height="0.818rem"
									:src="require('@/assets/images/yikaipiao.png')"
									class="invoiced qjc-absolute"
								/>
							</span>
							<span v-if="item.kp == 3" class="outline">請聯系門店獲取收據</span>
						</div>
					</div>
					<div class="cont qjc-texta-l qjc-relative">
						<h5 class="qjc-fts-28">{{ item.name }}</h5>
						<div class="time">
							消費時間：{{ item.pay_at }}
						</div>
						<van-icon
							v-if="item.kp != 3"
							name="arrow"
							class="arrow qjc-absolute"
						/>
					</div>
				</div>
			</van-list>
			<div v-else class="no-data qjc-fts-32">
				<van-image
					width="4.92rem"
					height="2.68rem"
					:src="require('@/assets/images/invoice_null.png')"
				/>
				<p class="qjc-fts-28">您暫時還沒有收據</p>
			</div>
		</div>
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Icon, List, Toast, Image } from 'vant'
	Vue.use(NavBar)
	   .use(Icon)
	   .use(List)
	   .use(Toast)
	   .use(Image);
	   
	import { invoiceList } from '@/js/axios.js'
	   
	export default{
		name: 'userInvoice',
		data (){
			return {
				page: 0,//当前页，默认第0页
				pagesize: 10,//每页多少条数据 默认10
				lists: [],//歷史列表數據
				loading: false,//是否處於加載狀態
				finished: false//是否加載完成
			}
		},
		computed: {
			hasData (){
				return this.lists.length>0?true:false;
			}
		},
		mounted() {
			this.getList();
			
			document.querySelector('body').setAttribute('style', 'background-color:#F5F7FA')
		},
		beforeDestroy() {
			document.querySelector('body').removeAttribute('style')
		},
		methods: {
			getList (){//獲取列表
				this.loading = true;
				invoiceList({
					access_token: localStorage.getItem('access_token'),
					page: ++this.page,
					pagesize: this.pagesize
				}).then(res => {
					console.log(res);
					if(res.status == 1){
						this.loading = false;
						this.lists = this.lists.concat(res.data.data);
						
						//所有數據已加載完成
						if(res.data.pager.pagecount == this.page){
							this.finished = true;
						}
					}else{
						Toast.fail(res.msg);
					}
				})
			},
			toInvoice (item){//開收據 
				if(item.kp == 1){
					//申請開票
					this.$router.push('/user/invoice/applyInvoice/'+item.id);
				}
				if(item.kp == 2){
					//已開票
					this.$router.push('/user/invoice/collection/'+item.receipt_id);
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	 .invoice{
		 margin-top: 0.88rem;
		 margin-bottom: 0.6rem;
		 padding: 0.24rem 0.3rem;
		 .van-list{
			 &>div{
				 background-color: #fff;
				 border-radius: 0.1rem;
				 margin-bottom: 0.14rem;
				 padding: 0.12rem 0.3rem;
			 }
			 .title{
				 line-height: 0.55rem;
				 color: #7F8899;
				 font-weight: 300;
				 border-bottom: 0.01rem solid #DCDEE5;
				 
				 .qjc-fr{
					 font-weight: 400;
				 }
				 .outline{
					 color: #A1A7B2;
					 font-weight: 400;
				 }
				 .invoiced{
					 right: 0;
					 top: 0;
				 }
			 }
			 .cont{
				 padding: 0.28rem 0;
				 
				 .time{
					 font-size: 0.2rem;
					 color: #A1A7B3;
					 font-weight: 300;
					 margin-top: 0.08rem;
				 }
				 .arrow{
					 top: 50%;
					 right: 0;
					 font-size: 0.24rem;
					 color: #7F8899;
				 }
			 }
		 }
		.no-data{
			margin-top: 2.6rem;
			
			p{
				color: #9A9FB4;
				margin-top: 0.6rem;
			}
		}
	 }
	 footer{
		 position: fixed;
		 left: 0;
		 bottom: 0;
		 width: 100%;
		 font-size: 0.2rem;
		 line-height: 0.62rem;
		 color: #999;
	 }
</style>
